import { useMemo, useState } from "react";

import { APIFilter } from "shared/api/utils";

import { DEFAULT_FILTER_TYPE } from "features/ui/Filters/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  getFilterGroupStateTopLevelRowAttributes,
  getTopLevelRowFromFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import StandardRelatesFilter from "features/ui/Filters/FilterTypes/RelatesFilter/StandardRelatesFilter";
import {
  FilterChangeCallback,
  FilterOperator,
  FilterSchemaItem,
} from "features/ui/Filters/types";
import {
  getDefaultOperatorSelectOption,
  operatorToSelectOption,
  prepareFilterValuesForAPI,
} from "features/ui/Filters/utils";
import { DataType } from "features/ui/Table/TableBodyCell";

import FilterTypeWrap from "./FilterTypeWrap";
import OperatorsDropdown from "./OperatorsDropdown";

interface Props {
  filter?: FilterSchemaItem;
  activeFilters?: FilterGroupState;
  onFiltersReset?: (fieldNames?: string[]) => void;
  onFilterChange?: FilterChangeCallback;
  staticFilters?: APIFilter[];
  filtersInitialized?: boolean;
  onValueOrOperatorChange: FilterChangeCallback;
  hideCTA?: boolean;
  defaultFilters?: FilterGroupState;
  pageKey: string;
}

const SingleFilter = ({
  filter,
  activeFilters,
  onFilterChange,
  onFiltersReset,
  staticFilters,
  filtersInitialized,
  onValueOrOperatorChange,
  hideCTA,
  defaultFilters,
  pageKey,
}: Props) => {
  const disableSelectFilters = filter?.disableSelectFilters || false;
  const disableContainsFilters = filter?.disableContainsFilters || false;
  const disableIsEmptyFilters = filter?.disableIsEmptyFilters || false;
  const disableStartsWithFilters = filter?.disableStartsWithFilters || false;
  const disableIsNotFilteredFilters =
    filter?.disableIsNotFilteredFilters || false;
  const whitelistedFilterOperators = filter?.whitelistedFilterOperators;
  const filterType = filter?.filterType || DEFAULT_FILTER_TYPE;
  const filterDataType = filter?.filterDataType || DataType.STRING;
  const DEFAULT_SELECT_OPERATOR = getDefaultOperatorSelectOption({
    type: filterType,
    disableSelectFilters,
    disableContainsFilters,
    disableIsEmptyFilters,
    disableStartsWithFilters,
    disableIsNotFilteredFilters,
    whitelistedFilterOperators,
  });

  const fieldName = filter?.fieldName;

  const currentFilter =
    activeFilters && fieldName
      ? getTopLevelRowFromFilterGroupState(fieldName, activeFilters)
      : undefined;

  const currentValues = currentFilter?.values ? currentFilter.values : [];

  const currentOperatorId = currentFilter?.operator
    ? currentFilter.operator
    : DEFAULT_SELECT_OPERATOR.id;

  const currentOperatorSelectOption = operatorToSelectOption(
    filterType,
    currentOperatorId
  );
  const [selectedOperator, setSelectedOperator] = useState(
    currentOperatorSelectOption
  );

  /**
   * Check if this filter is active:
   * We support direct match or match where key ends with _. We need this for date_, where we
   * want to activate icon on both date_to and date_from keys.
   */
  const hasActiveFilter = useMemo(() => {
    const activeFilterKeys =
      getFilterGroupStateTopLevelRowAttributes(activeFilters);

    // when activeFilters change, we need to update the selected operator as well
    setSelectedOperator(operatorToSelectOption(filterType, currentOperatorId));

    return activeFilterKeys.some(
      (f) =>
        fieldName === f || (fieldName?.endsWith("_") && f.startsWith(fieldName))
    );
  }, [fieldName, activeFilters, currentOperatorId, filterType]);

  if (!fieldName) return null;

  const currentFilterRow = getTopLevelRowFromFilterGroupState(
    fieldName,
    activeFilters
  );

  if (filterType === "relates") {
    // TODO mbencina: update stories
    return (
      <StandardRelatesFilter
        filters={activeFilters}
        defaultFilters={defaultFilters}
        filter={currentFilterRow}
        fieldName={fieldName}
        onFiltersReset={onFiltersReset}
        onFilterChange={onValueOrOperatorChange}
        initialized={filtersInitialized}
        baseEntityText={filter.baseEntityText}
        disableSelectingWindowDirection={filter.disableSelectingWindowDirection}
        pageKey={pageKey}
      />
    );
  }

  return (
    <div className="pt-2 px-2 text-left w-80 z-10">
      <div className="mb-3">
        <OperatorsDropdown
          type={filterType}
          selected={selectedOperator}
          onChange={(so) => {
            const newFilterOperatorId = so.id as FilterOperator;

            let values = prepareFilterValuesForAPI(
              currentValues,
              newFilterOperatorId
            );

            if (newFilterOperatorId === FilterOperator.NOT_FILTERED) {
              values = [];
            }

            onValueOrOperatorChange &&
              onValueOrOperatorChange({
                key: fieldName,
                op_id: newFilterOperatorId,
                values,
                extra: currentFilter?.extra,
                relates: currentFilter?.relates,
                dataType: filterDataType,
              });

            setSelectedOperator(so);
          }}
          disableSelectFilters={disableSelectFilters}
          disableContainsFilters={disableContainsFilters}
          disableIsEmptyFilters={disableIsEmptyFilters}
          disableStartsWithFilters={disableStartsWithFilters}
          disableIsNotFilteredFilters={disableIsNotFilteredFilters}
          whitelistedFilterOperators={whitelistedFilterOperators}
        />
      </div>
      <FilterTypeWrap
        isActiveFilter={hasActiveFilter}
        selectedOperatorId={selectedOperator.id}
        initialized={filtersInitialized}
        currentFilterSchema={filter}
        onFilterChange={onFilterChange}
        onFiltersReset={(fieldNames) => {
          onFiltersReset && onFiltersReset(fieldNames);
        }}
        onValuesChanged={(values) => {
          onValueOrOperatorChange &&
            onValueOrOperatorChange({
              key: fieldName,
              op_id: selectedOperator.id,
              values,
              dataType: filterDataType,
            });
        }}
        filters={activeFilters}
        staticFilters={staticFilters}
        hideCTA={hideCTA}
        defaultFilters={defaultFilters}
        pageKey={pageKey}
      />
    </div>
  );
};
export default SingleFilter;
