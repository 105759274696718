import React, { useState } from "react";

import { cloneObject } from "shared/utils";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { DEFAULT_RELATES_FILTER } from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import RelatesFilter from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilter";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { RelatesFilterState } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

import { OnRowDataChangeHandle } from "./FilterRow";

interface Props {
  id: string;
  relates?: RelatesFilterState | null;
  onRowDataChange: OnRowDataChangeHandle;
  attributeSchema?: SchemaEntry;
  baseEntityText?: string;
  disabled?: boolean;
}

const DEFAULT_BASE_ENTITY_TEXT = "Claims";

const RelatesFilterFields = ({
  id,
  relates,
  onRowDataChange,
  attributeSchema,
  disabled,
}: Props) => {
  const baseEntityText =
    attributeSchema?.filter?.baseEntityText ?? DEFAULT_BASE_ENTITY_TEXT;

  const [relatesFilter, setRelatesFilter] = useState<RelatesFilterState>(
    relates ? cloneObject(relates) : DEFAULT_RELATES_FILTER
  );

  // we need to have a different key for each relates filter across app
  const RELATED_FILTER_PAGE_KEY = `relatesFilterState-${id}`;
  const PENDING_FILTERS_RELATED_FILTER_LS_KEY = getPendingFiltersKey(
    RELATED_FILTER_PAGE_KEY
  );

  // this is a nested filter, so we need to fetch filters from the parent filter component
  const relatedSignalEventsFilterSortState = useFilterSortState({
    pageKey: RELATED_FILTER_PAGE_KEY,
    defaultFilterValues: relates?.filters ?? DEFAULT_RELATES_FILTER.filters,
    pendingFiltersLocalStorageKey: PENDING_FILTERS_RELATED_FILTER_LS_KEY,
    disableUsingQuery: true,
    disableUsingLocalStorage: true,
  });

  const onUpdate = (newRelatesState: RelatesFilterState) => {
    setRelatesFilter(newRelatesState);
    applyFilters(newRelatesState);
  };
  const applyFilters = (newRow: RelatesFilterState) => {
    onRowDataChange(id, "relates", newRow);
  };
  const applyRelatesFilter = (newFilterState: FilterGroupState) => {
    onUpdate({
      ...relatesFilter,
      filters: newFilterState,
    });
  };

  return (
    <div className="flex flex-col w-full space-y-2 ml-4">
      <RelatesFilter
        filterTitle="defined by"
        baseEntityText={baseEntityText}
        relatesFilter={relatesFilter}
        filterSortState={relatedSignalEventsFilterSortState}
        pendingFiltersKey={PENDING_FILTERS_RELATED_FILTER_LS_KEY}
        selectorTestId="related-signal-event-filters"
        inFilterSelector={true}
        onApplyFilters={applyRelatesFilter}
        onUpdate={onUpdate}
        defaultFilters={DEFAULT_RELATES_FILTER.filters}
        dropdownClassName="flex items-center space-x-2"
        disabled={disabled}
        disableSelectingWindowDirection={
          attributeSchema?.filter?.disableSelectingWindowDirection
        }
      />
    </div>
  );
};

export default RelatesFilterFields;
