import { AnyAll } from "./FilterGroup/AnyAllSelect";
import { FilterGroupState } from "./types";

export const FILTER_INDENT_MARGIN_PX = 20;
export const FILTER_INDENT_GROUP_MARGIN_PX = 4;
export const FILTER_DEFAULT_ANY_ALL: AnyAll = "all";
export const ANY_FILTER_SEPARATOR = "||";
export const ALL_FILTER_SEPARATOR = ",";

/**
 * We always start with just one top-level group
 * and we do not allow zero rows in a group
 */
export const DEFAULT_FILTER_BUILDER_STATE: FilterGroupState = {
  type: "group",
  id: "group-0",
  anyAll: FILTER_DEFAULT_ANY_ALL,
  children: [
    {
      type: "row",
      id: "row-0",
      attribute: null,
      operator: null,
      values: [],
      relates: null,
    },
  ],
};

export const DEFAULT_FILTER_BUILDER_STATE_ANY: FilterGroupState = {
  type: "group",
  id: "group-0",
  anyAll: "any",
  children: [
    {
      type: "row",
      id: "row-0",
      attribute: null,
      operator: null,
      values: [],
      relates: null,
    },
  ],
};

export const FILTER_ROW_OPACITY_PERCENTAGE_INCREASE = 3;
