import React from "react";

import { useSignalEventOccurrencesFiltersSchema } from "shared/hooks";

import { SIGNAL_EVENTS_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";

import DropdownSelect from "features/ui/DropdownSelect";
import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import RelatesTimeWindowForm from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import {
  RelatesFilterState,
  RelatesFilterWindowDirectionType,
  UseFilterSortState,
} from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

interface RelatesFilterProps {
  filterTitle: string;
  baseEntityText: string;
  relatesFilter: RelatesFilterState;
  filterSortState: UseFilterSortState;
  pendingFiltersKey: string;
  selectorTestId: string;
  inFilterSelector: boolean;
  onApplyFilters: (filters: FilterGroupState) => void;
  onUpdate: (row: RelatesFilterState) => void;
  defaultFilters?: FilterGroupState;
  dropdownClassName?: string;
  disabled?: boolean;
  windowDirectionOptions?: DropdownSelectOption<RelatesFilterWindowDirectionType>[];
  disableSelectingWindowDirection?: boolean;
}

const RelatesFilter = ({
  filterTitle,
  baseEntityText,
  relatesFilter,
  filterSortState,
  pendingFiltersKey,
  selectorTestId,
  inFilterSelector,
  onApplyFilters,
  onUpdate,
  defaultFilters,
  dropdownClassName,
  disabled,
  windowDirectionOptions,
  disableSelectingWindowDirection,
}: RelatesFilterProps) => {
  const signalEventsFilterLabel = getFilterLabel(
    SIGNAL_EVENTS_FILTER_LABEL,
    filterSortState.filters
  );

  // we want to avoid nested related filters
  const signalEventsOccurrencesFiltersSchema =
    useSignalEventOccurrencesFiltersSchema(["relatedSignalEventOccurrences"]);

  const dropdownContent = (
    <>
      <span>{filterTitle}</span>
      <DropdownSelect
        testId="associated-signal-events-filters-dropdown"
        label={signalEventsFilterLabel}
        buttonClass="mt-6 h-[38px] mr-4"
        disabled={disabled}
        content={
          <FilterSelector
            schema={signalEventsOccurrencesFiltersSchema}
            filterSortState={filterSortState}
            title={SIGNAL_EVENTS_FILTER_LABEL}
            testId={selectorTestId}
            pendingFiltersKey={pendingFiltersKey}
            initialIsAdvancedFilter={false}
            onApplyFilters={onApplyFilters}
            defaultFilters={defaultFilters}
          />
        }
      />
    </>
  );

  return (
    <>
      {dropdownClassName ? (
        <div className={dropdownClassName}>{dropdownContent}</div>
      ) : (
        dropdownContent
      )}
      <RelatesTimeWindowForm
        relatesState={relatesFilter}
        onUpdate={onUpdate}
        baseEntityText={baseEntityText}
        inFilterSelector={inFilterSelector}
        windowDirectionOptions={windowDirectionOptions}
        disabled={disabled}
        disableSelectingWindowDirection={disableSelectingWindowDirection}
      />
    </>
  );
};

export default RelatesFilter;
