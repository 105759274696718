import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { FilterState } from "features/ui/Filters/types";

export const getFilterPresenterNumOfLines = (
  filterState?: FilterState | FilterGroupState | FilterRowState,
  count: number = 0
): number => {
  if (!filterState) return count;

  if (
    "type" in filterState &&
    filterState.type === "group" &&
    filterState.children.length === 0
  ) {
    return 0;
  }

  if ("type" in filterState && filterState.type === "group") {
    return (filterState as FilterGroupState).children.reduce(
      (acc, child) => getFilterPresenterNumOfLines(child, acc),
      count + 1
    );
  }

  if (
    "relates" in filterState &&
    (filterState as FilterRowState).relates != null
  ) {
    return getFilterPresenterNumOfLines(
      (filterState as FilterRowState).relates?.filters,
      count + 2
    );
  }

  return count + 1;
};

export const getMaxNumOfLinesForFilterPresenter = (
  filters: (string | undefined)[]
) => {
  const filterObjects = filters.map((filter) =>
    filterBuilderQueryToFilterBuilderState(filter)
  );
  const numOfLines = filterObjects.map((filter) =>
    getFilterPresenterNumOfLines(filter)
  );

  return Math.max(...numOfLines);
};
