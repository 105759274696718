import {
  ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
  ATTRIBUTE_RETRIEVAL_K,
  ATTRIBUTE_SOURCE_KEYS,
} from "duck/graph/constants";
import {
  FilterSource,
  getValidateFilter,
} from "duck/graph/nodes/SignalEventAnalyticsAgent/utils";
import { PageHandler } from "duck/graph/PageHandler";
import getAttributeRetrievalTool from "duck/graph/tools/getAttributeRetrievalTool";
import getGoToAssociatedSignalEventsTabTool from "duck/graph/tools/getGoToAssociatedSignalEventsTabTool";
import getGoToByVehiclesAgeTabTool from "duck/graph/tools/getGoToByVehiclesAgeTabTool";
import getGoToTabTool from "duck/graph/tools/getGoToTabTool";
import getGoToTopContributorsTabTool from "duck/graph/tools/getGoToTopContributorsTabTool";
import getRetrieveTopContributorsGroupByOptionsTool from "duck/graph/tools/getRetrieveTopContributorsGroupByOptionsTool";
import getUpdateFilterTool from "duck/graph/tools/getUpdateFilterTool";
import { DuckGraphParams } from "duck/graph/types";

import {
  ASSOCIATED_VEHICLES_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_TITLE,
} from "pages/constants";
import {
  SIGNAL_EVENTS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/SignalEventsAnalytics/constants";
import { SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY } from "pages/SignalEventsAnalytics/tabPages/ByVehicleAge/ByVehicleAge";
import {
  DEFAULT_GROUP_BY_ATTRIBUTE,
  SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
  SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
} from "pages/SignalEventsAnalytics/tabPages/TopContributors/TopContributors";

import { RelatesFilterWindowDirection } from "features/ui/Filters/types";

import getGoToAssociatedClaimsTabTool from "./getGoToAssociatedClaimsTabTool";
import getGoToSignalEventsTabTool from "./getGoToSignalEventsTabTool";

/**
 * @summary Get the tools for the signal event analytics agent.
 * The tools are specific to signal event analytics, and are bound to the UI handlers
 * for signal event analytics.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * @returns The full set of tools for the signal event analytics agent.
 */
export const getRetrievalTools = (params: DuckGraphParams) => [
  getAttributeRetrievalTool(
    ATTRIBUTE_SOURCE_KEYS.SignalEventOccurrences,
    ATTRIBUTE_RETRIEVAL_K,
    ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
    params.uiHandlers.setEphemeralMessage
  ),
  getAttributeRetrievalTool(
    ATTRIBUTE_SOURCE_KEYS.Vehicle,
    ATTRIBUTE_RETRIEVAL_K,
    ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
    params.uiHandlers.setEphemeralMessage
  ),
  getRetrieveTopContributorsGroupByOptionsTool(
    params.availableData.signalEventAnalytics.topContributorsGroupByOptions,
    params.uiHandlers.setEphemeralMessage
  ),
];

export const getActionTools = (
  params: DuckGraphParams,
  pageHandler: PageHandler
) => {
  const duckAccess = params.uiHandlers.duckAccess;
  const tools = [
    getUpdateFilterTool({
      pageHandler,
      pageKey: SIGNAL_EVENTS_PAGE_KEY,
      validateFunction: getValidateFilter(FilterSource.SIGNAL_EVENT_OCCURRENCE),
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
      setAgentResponse: params.uiHandlers.setAgentResponse,
      source: FilterSource.SIGNAL_EVENT_OCCURRENCE,
      selectedFilterString:
        params.currentState.signalEventAnalytics.signalEventsFilterQueryString,
    }),
    getUpdateFilterTool({
      pageHandler,
      pageKey: VEHICLES_PAGE_KEY,
      validateFunction: getValidateFilter(FilterSource.VEHICLE),
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
      setAgentResponse: params.uiHandlers.setAgentResponse,
      source: FilterSource.VEHICLE,
      selectedFilterString:
        params.currentState.signalEventAnalytics.vehiclesFilterQueryString,
    }),
    getGoToSignalEventsTabTool({
      pageHandler,
      signalEventsChartOptions:
        params.availableData.signalEventAnalytics.signalEventsChartOptions,
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
      setAgentResponse: params.uiHandlers.setAgentResponse,
      selectedTab: params.currentState.selectedTab,
      selectedChartOptions:
        params.currentState.signalEventAnalytics
          .selectedSignalEventsChartOptions,
    }),
    getGoToByVehiclesAgeTabTool({
      pageHandler,
      chartOptions:
        params.availableData.signalEventAnalytics.byVehicleAgeChartOptions,
      pageKey: SIGNAL_EVENTS_PAGE_KEY,
      chartOptionsKey: SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY,
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
      setAgentResponse: params.uiHandlers.setAgentResponse,
      selectedTab: params.currentState.selectedTab,
      selectedByVehicleAgeChartOptions:
        params.currentState.signalEventAnalytics
          .selectedByVehicleAgeChartOptions,
    }),
    getGoToTopContributorsTabTool({
      pageHandler,
      chartOptions:
        params.availableData.signalEventAnalytics.topContributorsChartOptions,
      groupByOptions:
        params.availableData.signalEventAnalytics.topContributorsGroupByOptions,
      selectedTab: params.currentState.selectedTab,
      selectedChartOptions:
        params.currentState.signalEventAnalytics
          .selectedTopContributorsChartOptions,
      selectedGroupByAttribute:
        params.currentState.signalEventAnalytics
          .selectedTopContributorsGroupByAttribute,
      defaultGroupByAttribute: DEFAULT_GROUP_BY_ATTRIBUTE,
      pageKey: SIGNAL_EVENTS_PAGE_KEY,
      chartOptionsKey: SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
      groupByOptionsKey: SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
      setAgentResponse: params.uiHandlers.setAgentResponse,
    }),
    getGoToTabTool({
      pageHandler,
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
      setAgentResponse: params.uiHandlers.setAgentResponse,
      tabKey: ASSOCIATED_VEHICLES_TAB_KEY,
      tabTitle: ASSOCIATED_VEHICLES_TAB_TITLE,
      selectedTabKey: params.currentState.selectedTab,
    }),
    getGoToAssociatedClaimsTabTool({
      pageHandler,
      groupByOptions:
        params.availableData.signalEventAnalytics
          .associatedClaimsGroupByOptions,
      selectedOptions:
        params.currentState.signalEventAnalytics
          .selectedAssociatedClaimsOptions,
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
      setAgentResponse: params.uiHandlers.setAgentResponse,
      selectedTab: params.currentState.selectedTab,
    }),
  ];
  if (duckAccess.signalEventAnalytics.associatedSignalEventsTab) {
    tools.push(
      getGoToAssociatedSignalEventsTabTool({
        pageHandler,
        pageKey: SIGNAL_EVENTS_PAGE_KEY,
        windowDirectionOptions: [RelatesFilterWindowDirection.BEFORE],
        setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
        setAgentResponse: params.uiHandlers.setAgentResponse,
        selectedTab: params.currentState.selectedTab,
        selectedWindowSize:
          params.currentState.signalEventAnalytics
            .selectedAssociatedSignalEventsOptions.windowSize,
        selectedWindowDirection:
          params.currentState.signalEventAnalytics
            .selectedAssociatedSignalEventsOptions.windowDirection,
      })
    );
  }

  return tools;
};
