import { DuckMessage } from "duck/context/DuckContextWrapper";
import { DuckMessageFormat } from "duck/context/types";
import { DuckAccess } from "duck/ui/types";
import { StreamEvent } from "@langchain/core/dist/tracers/event_stream";

import {
  IssuesChartActionIdType,
  SuggestedIssuesChartActionIdType,
} from "pages/Issues/constants";
import { SignalEventsActionIdType } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/utils";
import {
  ByVehicleAgeActionIdType,
  ClaimsActionIdType,
  TopContributorActionIdType,
  VinViewTimelineActionIdType,
} from "pages/utils";

import { RelatesFilterWindowDirection } from "features/ui/Filters/types";

export type NonEmptyStringArray = [string, ...string[]]; // There must be at least one element in the array

export const MODEL_NAMES = {
  GPT4O_MINI: "gpt-4o-mini",
  GPT4O: "gpt-4o",
} as const;

export type ModelName = (typeof MODEL_NAMES)[keyof typeof MODEL_NAMES];

export interface ModelSpec {
  modelName: ModelName;
  temperature: number;
  streaming: boolean;
  modelKwargs: {
    seed: number;
  };
  maxTokens?: number;
}
export type ClaimsChartOptionStrings = Record<
  ClaimsActionIdType,
  NonEmptyStringArray
>;

// This type is shared between the claim analytics and signal events analytics pages
export type ByVehicleAgeChartOptionStrings = Record<
  ByVehicleAgeActionIdType,
  NonEmptyStringArray
>;

/**
 * The state of the claim analytics page.
 */
interface ClaimAnalyticsPageState {
  claimsFilterQueryString: string;
  vehiclesFilterQueryString: string;
  selectedClaimsChartOptions: Record<ClaimsActionIdType, string>;
  selectedByVehicleAgeChartOptions: Record<ByVehicleAgeActionIdType, string>;
  selectedGroupByAttribute: string;
  selectedTopContributorsChartOptions: Record<
    TopContributorActionIdType,
    string
  >;
  signalEventOccurrencesFilterQueryString: string;
  signalEventOccurrencesWindowSize: number;
  signalEventOccurrencesWindowDirection: string;
}

export type SignalEventsChartOptionStrings = Record<
  SignalEventsActionIdType,
  NonEmptyStringArray
>;

export interface SignalEventAnalyticsAssociatedClaimsOptions {
  filterQueryString: string;
  windowSize: number;
  groupByAttribute: string;
}

export interface SignalEventAnalyticsAssociatedSignalEventsOptions {
  filterQueryString: string;
  windowSize: number;
  windowDirection: RelatesFilterWindowDirection;
}

interface SignalEventAnalyticsPageState {
  signalEventsFilterQueryString: string;
  vehiclesFilterQueryString: string;
  selectedSignalEventsChartOptions: Record<ClaimsActionIdType, string>;
  selectedByVehicleAgeChartOptions: Record<ByVehicleAgeActionIdType, string>;
  selectedTopContributorsChartOptions: Record<
    TopContributorActionIdType,
    string
  >;
  selectedTopContributorsGroupByAttribute: string;
  selectedAssociatedClaimsOptions: SignalEventAnalyticsAssociatedClaimsOptions;
  selectedAssociatedSignalEventsOptions: SignalEventAnalyticsAssociatedSignalEventsOptions;
}

interface VehiclesPageState {
  vehiclesFilterQueryString: string;
  vehiclesSortQueryString: string;
}

interface VinViewPageState {
  selectedTimelineChartOptions: Record<VinViewTimelineActionIdType, string>;
  selectedSensorsAndTriggers: string;
  selectedDateRange: string;
  selectedSignalEventFilters: string;
}

interface IssuesPageState {
  issuesFilterQueryString: string;
  suggestedIssuesFilterQueryString: string;
  issuesSortQueryString: string;
  suggestedIssuesSortQueryString: string;
  // TODO: Add char option types
  selectedIssuesChartOptions: Record<string, string>;
  selectedSuggestedIssuesChartOptions: Record<string, string>;
}

interface IssueClaimsChartState {
  // TODO: Add char option types
  selectedOccurrencesByCalendarTimeChartOptions: Record<string, string>;
  selectedTopXByCalendarTimeChartOptions: Record<string, string>;
  selectedOccurrencesByVehicleAgeChartOptions: Record<string, string>;
  selectedTopXByVehicleAgeChartOptions: Record<string, string>;
}

interface IssueSignalEventsChartState {
  // TODO: Add char option types
  selectedOccurrencesByCalendarTimeChartOptions: Record<string, string>;
  selectedTopXByCalendarTimeChartOptions: Record<string, string>;
  selectedOccurrencesByVehicleAgeChartOptions: Record<string, string>;
  selectedTopXByVehicleAgeChartOptions: Record<string, string>;
}

interface issueRelationshipChartState {
  // TODO: Add Time Period options
  // TODO: Add char option types
  selectedOccurrencesByCalendarTimeChartOptions: Record<string, string>;
  selectedOccurrencesByVehicleAgeChartOptions: Record<string, string>;
  selectedAssociatedSignalEventsChartOptions: Record<string, string>;
}

interface IssueRepairEfficacyChartState {
  // TODO: Add Time Period options
  // TODO: Add char option types
  selectedReoccurrenceByAttributeChartOptions: Record<string, string>;
  selectedReoccurrenceByPopulationChartOptions: Record<string, string>;
  selectedReoccurrenceProceedingAClaimChartOptions: Record<string, string>;
}

interface IssueDetailsPageState {
  selectedClaimsChartOptions: IssueClaimsChartState;
  selectedSignalEventsChartOptions: IssueSignalEventsChartState;
  selectedRelationshipChartOptions: issueRelationshipChartState;
  selectedRepairEfficacyChartOptions: IssueRepairEfficacyChartState;
}

export interface PageState {
  pathname: string;
  selectedTab: string;
  claimAnalytics: ClaimAnalyticsPageState;
  signalEventAnalytics: SignalEventAnalyticsPageState;
  vehicles: VehiclesPageState;
  vinView: VinViewPageState;
  issues: IssuesPageState;
  issueDetails: IssueDetailsPageState;
}

interface ClaimAnalyticsAvailableData {
  topContributorsGroupByOptions: NonEmptyStringArray;
  topContributorsChartOptions: TopContributorsChartHierarchicalOptionStrings;
  byVehicleAgeChartOptions: ByVehicleAgeChartOptionStrings;
  claimsChartOptions: ClaimsChartOptionStrings;
}

interface SignalEventAnalyticsAvailableData {
  signalEventsChartOptions: SignalEventsChartOptionStrings;
  byVehicleAgeChartOptions: ByVehicleAgeChartOptionStrings;
  topContributorsChartOptions: TopContributorsChartHierarchicalOptionStrings;
  topContributorsGroupByOptions: NonEmptyStringArray;
  associatedClaimsGroupByOptions: NonEmptyStringArray;
  associatedSignalEventsWindowDirectionOptions: NonEmptyStringArray;
}

export type VinViewTimelineChartOptionStrings = Record<
  VinViewTimelineActionIdType,
  NonEmptyStringArray
>;

interface VinViewAvailableData {
  timelineChartOptions: VinViewTimelineChartOptionStrings;
  sensorOptions: NonEmptyStringArray;
}

export type IssuesChartOptionStrings = Record<
  IssuesChartActionIdType,
  NonEmptyStringArray
>;

export type SuggestedIssuesChartOptionStrings = Record<
  SuggestedIssuesChartActionIdType,
  NonEmptyStringArray
>;

export interface IssuesAvailableData {
  issuesChartOptions: IssuesChartOptionStrings;
  suggestedIssuesChartOptions: SuggestedIssuesChartOptionStrings;
}

export interface AvailableData {
  claimAnalytics: ClaimAnalyticsAvailableData;
  signalEventAnalytics: SignalEventAnalyticsAvailableData;
  vinView: VinViewAvailableData | null;
  issues: IssuesAvailableData;
}

export interface StringSetter {
  (value: string): void;
}

type HasSource = {
  source: string;
};

type FilterOptions = HasSource;

type SortOptions = HasSource;

export interface SetAgentResponseOptions {
  format?: DuckMessageFormat;
  triggerAgentPreludeMessage?: boolean;
  filterOptions?: FilterOptions;
  sortOptions?: SortOptions;
}

/**
 * The handler functions and properties that are provided by the UI for the claim analytics agent.
 */
export interface UIHandlers {
  setAgentResponse: (
    message: string,
    options?: SetAgentResponseOptions
  ) => void;
  setEphemeralMessage: StringSetter;
  duckAccess: DuckAccess;
  handleStreamEvent: (streamEvent: StreamEvent) => void;
}

/**
 * The parameters that are passed to the agent.
 */
export interface DuckGraphParams {
  text: string;
  messageHistory: DuckMessage[];
  uiHandlers: UIHandlers;
  threadId: string;
  tenant: string;
  user: string;
  currentState: PageState;
  availableData: AvailableData;
  captureImages: boolean;
}

export type TopContributorsChartHierarchicalOptionStrings = {
  y: NonEmptyStringArray;
  // Each key is the id of an exposure option and
  // the values are the ids of the exposure bucket options
  exposure: Record<string, NonEmptyStringArray>;
};

/**
 * Options for the source of the code descriptions.
 */
export type CodeDescriptionSourceOptions = "parts" | "laborCodes";
