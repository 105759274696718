import classNames from "classnames";

import { APIFilter } from "shared/api/utils";

import {
  DEFAULT_FILTER_TYPE,
  DEFAULT_OPERATOR_PER_TYPE,
  GT_LT_GTE_LTE_OPERATORS,
  OPERATORS_ALLOW_APPLY_EVEN_IF_EMPTY,
} from "features/ui/Filters/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getTopLevelRowFromFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import ContainsStartsWithFilter from "features/ui/Filters/FilterTypes/ContainsStartsWithFilter";
import DateFilters from "features/ui/Filters/FilterTypes/DateFilters";
import DurationFilter from "features/ui/Filters/FilterTypes/DurationFilter";
import ExistsFilter from "features/ui/Filters/FilterTypes/ExistsFilter/ExistsFilter";
import NumberFilters from "features/ui/Filters/FilterTypes/NumberFilters";
import SelectFilter from "features/ui/Filters/FilterTypes/SelectFilter";
import ResetToDefaultButton from "features/ui/Filters/FilterWizard/ResetToDefaultButton";
import {
  FilterChangeCallback,
  FilterOperator,
  FilterSchemaItem,
} from "features/ui/Filters/types";
import { prepareFilterValuesForAPI } from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";

import FilterCTA from "./FilterCTA";

interface Props {
  isActiveFilter: boolean;
  selectedOperatorId: FilterOperator;
  currentFilterSchema: FilterSchemaItem;
  filters?: FilterGroupState;
  staticFilters?: APIFilter[];
  hideFilters?: string[];
  hideCTA?: boolean;
  onFiltersReset?: (fieldNames?: string[]) => void;
  onFilterChange?: FilterChangeCallback;
  onValuesChanged: (values: string[]) => void;
  initialized?: boolean;
  horizontal?: boolean;
  defaultFilters?: FilterGroupState;
  className?: string;
  disabled?: boolean;
  isAdvancedFilterEditor?: boolean;
  pageKey?: string;
}

const EXISTS_ADDITIONAL_TEXT = (
  <div className="order-3 leading-10 flex-1 truncate">
    where <b>All</b> of the following are true
  </div>
);

const FilterTypeWrap = ({
  isActiveFilter,
  selectedOperatorId,
  currentFilterSchema,
  filters,
  staticFilters,
  hideFilters,
  hideCTA,
  onFiltersReset,
  onFilterChange,
  onValuesChanged,
  defaultFilters,
  className,
  disabled,
  isAdvancedFilterEditor,
  pageKey,
  ...otherProps
}: Props) => {
  const {
    filterType = DEFAULT_FILTER_TYPE,
    label,
    fieldName,
    onlyAllowPositiveIntegers,
    filterDataType,
    relationEndpoint,
    ...otherFilterSchemaProps
  } = currentFilterSchema;

  const childRow = getTopLevelRowFromFilterGroupState(fieldName, filters);

  const prevFilterOperator: FilterOperator =
    (childRow && childRow.operator) || DEFAULT_OPERATOR_PER_TYPE[filterType];

  const currentFilterValues: string[] = (childRow && childRow.values) || [];

  const selectedValuesOptions: SelectOption[] = currentFilterValues.map(
    (s) => ({ id: s, value: s })
  );

  const inputValue1 = currentFilterValues[0] || "";
  const inputValue2 = currentFilterValues[1] || "";

  const handleOnFilterChange = () => {
    onFilterChange &&
      onFilterChange({
        key: fieldName,
        op_id: selectedOperatorId,
        values: currentFilterValues,
        dataType: currentFilterSchema.filterDataType,
      });
  };

  const handleOnValuesChange = (values: string[]) => {
    onValuesChanged(prepareFilterValuesForAPI(values, selectedOperatorId));
  };

  if (hideFilters?.includes(fieldName)) return null;

  const operatorHasChanged = prevFilterOperator !== selectedOperatorId;

  const applyCtaEnabled =
    operatorHasChanged ||
    OPERATORS_ALLOW_APPLY_EVEN_IF_EMPTY.includes(selectedOperatorId) ||
    currentFilterValues.length > 0;

  const isAdvancedExistsEditor =
    isAdvancedFilterEditor &&
    [FilterOperator.EXISTS, FilterOperator.NOT_EXISTS].includes(
      selectedOperatorId
    );

  return (
    <>
      {isAdvancedExistsEditor && EXISTS_ADDITIONAL_TEXT}
      <div
        className={classNames("flex-1", className, {
          "basis-full order-4": isAdvancedExistsEditor,
        })}
        data-testid={`filter-type-wrap-${fieldName}`}
      >
        {[FilterOperator.IN, FilterOperator.NOT_IN].includes(
          selectedOperatorId
        ) && (
          <SelectFilter
            label={label}
            key={`${label}-${selectedOperatorId}`}
            initialSelected={selectedValuesOptions}
            fieldName={fieldName}
            onChange={handleOnValuesChange}
            staticFilters={staticFilters}
            filterType={filterType}
            filterDataType={filterDataType}
            disabled={disabled}
            {...otherProps}
            {...otherFilterSchemaProps}
          />
        )}
        {relationEndpoint &&
          [FilterOperator.EXISTS, FilterOperator.NOT_EXISTS].includes(
            selectedOperatorId
          ) && (
            <ExistsFilter
              label={label}
              key={`${label}-${selectedOperatorId}`}
              fieldName={fieldName}
              onChange={handleOnValuesChange}
              disabled={disabled}
              currentFilterValues={currentFilterValues}
              isAdvancedFilterEditor={isAdvancedFilterEditor}
              relationEndpoint={relationEndpoint}
              pageKey={pageKey}
              {...otherProps}
              {...otherFilterSchemaProps}
            />
          )}
        {[
          FilterOperator.CONTAINS,
          FilterOperator.NOT_CONTAINS,
          FilterOperator.STARTS_WITH,
          FilterOperator.NOT_STARTS_WITH,
        ].includes(selectedOperatorId) && (
          <ContainsStartsWithFilter
            fieldName={fieldName}
            currentFilterValues={currentFilterValues}
            onChange={handleOnValuesChange}
            disabled={disabled}
          />
        )}
        {filterType === "number" && (
          <NumberFilters
            currentFilterSchema={currentFilterSchema}
            selectedOperatorId={selectedOperatorId}
            inputValueOne={inputValue1}
            inputValueTwo={inputValue2}
            onInputOneChange={(val) => handleOnValuesChange([val, inputValue2])}
            onInputTwoChange={(val) => handleOnValuesChange([inputValue1, val])}
            onlyAllowPositiveIntegers={onlyAllowPositiveIntegers}
            disabled={disabled}
          />
        )}
        {filterType === "date" && (
          <DateFilters
            selectedOperatorId={selectedOperatorId}
            fieldName={fieldName}
            initialValues={currentFilterValues}
            onDateFromChange={(val) => handleOnValuesChange([val, inputValue2])}
            onDateToChange={(val) => handleOnValuesChange([inputValue1, val])}
            disabled={disabled}
            filterType={filterType}
            dataType={filterDataType}
          />
        )}
        {filterType === "duration" &&
          GT_LT_GTE_LTE_OPERATORS.includes(selectedOperatorId) && (
            <DurationFilter
              fieldName={fieldName}
              value={currentFilterValues.length ? currentFilterValues[0] : ""}
              disabled={disabled}
              onChange={(val) => handleOnValuesChange([val])}
            />
          )}
        {onFiltersReset && (
          <div className="ml-auto flex items-center justify-end">
            <ResetToDefaultButton
              onResetToDefault={onFiltersReset}
              fieldName={fieldName}
              disabled={disabled}
            />
          </div>
        )}
        {!hideCTA && (
          <div className="flex items-center justify-between border-t pt-2 mt-2">
            <div className="ml-auto">
              <FilterCTA
                disabled={!applyCtaEnabled || disabled}
                onClick={handleOnFilterChange}
                testId={`filter-cta-${fieldName}`}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FilterTypeWrap;
