import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AvailableData, PageState } from "duck/graph/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import qs, { ParsedQs } from "qs";
import { createPath, useNavigate } from "react-router";

import { TAB_QUERY_PARAM } from "shared/constants";
import { VehicleECUsAttributeContext } from "shared/contexts/VehicleECUsAttributesContextWrapper";
import { VehicleOptionsAttributeContext } from "shared/contexts/VehicleOptionsAttributesContextWrapper";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import {
  USE_RESOURCE_SCHEMA_MAP,
  VEHICLE_ACCESSOR,
} from "shared/schemas/constants";
import useIssuesSchema from "shared/schemas/issuesSchema";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";
import useSuggestedIssuesSchema from "shared/schemas/useSuggestedIssuesSchema";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { EventTypeEnum, EventTypeLabel } from "shared/types";
import { randomID } from "shared/utils";

import { useByVehicleAgeChartActions as useClaimAnalyticsByVehicleAgeChartActions } from "pages/ClaimAnalytics/tabPages/ByVehicleAge/hooks";
import { useClaimsChartActions } from "pages/ClaimAnalytics/tabPages/Claims/hooks";
import { useTopContributorsExposureOptions } from "pages/hooks";
import { useTopContributorsChartYAxisOptions } from "pages/shared/topContributorsChartActions";
import { useGetByVehicleAgeChartActions as useSignalEventAnalyticsByVehicleAgeChartActions } from "pages/SignalEventsAnalytics/tabPages/ByVehicleAge/hooks";
import { getSignalEventChartActions } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/utils";
import { topContributorsChartYAxisOptions } from "pages/SignalEventsAnalytics/tabPages/TopContributors/ChartActions";

import { WINDOW_DIRECTION_OPTIONS } from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import { SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";

import { useQuery } from "services/hooks";

import {
  DUCK_OMIT_EXISTING_QUERY_PARAM_KEY,
  DUCK_OMIT_EXISTING_QUERY_PARAMS_STARTING_WITH_KEY,
  DUCK_PENDING_ACTION_KEY,
  DUCK_RELOAD_REQUIRED_KEY,
  DUCK_ROUTE_VALUE_KEY,
  DUCK_UPDATED_QUERY_PARAMS_KEY,
  LANGCHAIN_THREAD_ID_KEY,
} from "./constants";
import {
  DuckAccess,
  LocationInfo,
  PendingAction,
  QueryStringNavigation,
  Reload,
} from "./types";
import {
  assertNonEmptyStringArray,
  createExposureHierarchy,
  getByVehicleAgeChartOptionStrings,
  getClaimsChartOptionStrings,
  getDuckAccess,
  getInitialThreadId,
  getInitialUpdatedQueryParams,
  getInitialVisibility,
  getIssuesAgentData,
  getPageState,
  getSignalEventsChartOptionStrings,
  getVinViewAgentData,
  persistVisibility,
  toEncodedNonEmptyStringArray,
  toNonEmptyStringArray,
} from "./utils";

const flatten = (options: SelectOption[], prefix = ""): SelectOption[] => {
  const flattened: SelectOption[] = [];
  options.forEach((option) => {
    if (option.children && option.children.length > 0) {
      flattened.push(...flatten(option.children, `${prefix}${option.id}.`));
    } else {
      flattened.push({
        id: `${prefix}${option.id}`,
        value: option.value,
      });
    }
  });

  return flattened;
};

/**
 * The useGroupBySelectOptions hook temporarily returns an empty array while the data loads.
 * This wrapper hook ensures that the array is never empty by putting a placeholder in it.
 */
const useNonEmptyGroupBySelectOptions = (
  eventType: EventTypeEnum,
  skipVehicleAttributes?: boolean
): SelectOption[] => {
  const { groupBySelectOptions } = USE_RESOURCE_SCHEMA_MAP[eventType](
    skipVehicleAttributes ? [VEHICLE_ACCESSOR] : undefined
  );

  if (!groupBySelectOptions || groupBySelectOptions.length === 0) {
    return [
      {
        id: "placeholder",
        value: "Placeholder While Data Loads",
      },
    ];
  }

  return flatten(groupBySelectOptions);
};

const useClaimAnalyticsAgentData = (): AvailableData["claimAnalytics"] => {
  const claimsChartOptions = useClaimsChartActions();
  const byVehicleAgeChartActions = useClaimAnalyticsByVehicleAgeChartActions(
    EventTypeLabel.CLAIM
  );

  const groupBySelectOptions = useNonEmptyGroupBySelectOptions(
    EventTypeEnum.CLAIM
  );

  const topContributorsYAxisOptions = useTopContributorsChartYAxisOptions(
    EventTypeEnum.CLAIM
  );

  const { attributes } = useClaimsSchema();

  const topContributorsExposures = useTopContributorsExposureOptions(
    EventTypeEnum.CLAIM
  );

  const exposuresWithBuckets = createExposureHierarchy(
    topContributorsExposures,
    attributes
  );

  return {
    claimsChartOptions: getClaimsChartOptionStrings(claimsChartOptions),
    byVehicleAgeChartOptions: getByVehicleAgeChartOptionStrings(
      byVehicleAgeChartActions
    ),
    topContributorsGroupByOptions:
      toEncodedNonEmptyStringArray(groupBySelectOptions),
    topContributorsChartOptions: {
      y: toNonEmptyStringArray(topContributorsYAxisOptions),
      exposure: exposuresWithBuckets,
    },
  };
};

const useSignalEventsAnalyticsAgentData =
  (): AvailableData["signalEventAnalytics"] => {
    const signalEventsActions = getSignalEventChartActions();
    const byVehicleAgeActions = useSignalEventAnalyticsByVehicleAgeChartActions(
      EventTypeLabel.SIGNAL_EVENT
    );
    const topContributorsExposures = useTopContributorsExposureOptions(
      EventTypeEnum.SIGNAL_EVENT
    );
    const { attributes } = useSignalEventOccurrencesSchema();
    const topContributorsGroupBySelectOptions = useNonEmptyGroupBySelectOptions(
      EventTypeEnum.SIGNAL_EVENT
    );
    const associatedClaimsGroupBySelectOptions =
      useNonEmptyGroupBySelectOptions(EventTypeEnum.CLAIM, true);

    const associatedSignalEventsWindowDirectionOptions =
      WINDOW_DIRECTION_OPTIONS.map(
        (windowDirectionOption) => windowDirectionOption.id
      );
    assertNonEmptyStringArray(associatedSignalEventsWindowDirectionOptions);

    return {
      signalEventsChartOptions:
        getSignalEventsChartOptionStrings(signalEventsActions),
      byVehicleAgeChartOptions:
        getByVehicleAgeChartOptionStrings(byVehicleAgeActions),
      topContributorsChartOptions: {
        y: toNonEmptyStringArray(topContributorsChartYAxisOptions),
        exposure: createExposureHierarchy(topContributorsExposures, attributes),
      },
      topContributorsGroupByOptions: toEncodedNonEmptyStringArray(
        topContributorsGroupBySelectOptions
      ),
      associatedClaimsGroupByOptions: toNonEmptyStringArray(
        associatedClaimsGroupBySelectOptions
      ),
      associatedSignalEventsWindowDirectionOptions,
    };
  };

/**
 * This hook assembles the data that the Duck agent needs in order to do its work.
 * For now, all of this data is related to the claim analytics page.
 *
 * Most of the data is relatively static, and is provided at the time the hook runs.
 * The hook also provides a getPageState function that can be called at the time
 * the agent is called in order to get the dynamic data that is needed.
 */
export const useAgentData = (): {
  availableData: AvailableData;
  getPageState: () => PageState;
} => {
  const [vinView, setVinView] = useState<AvailableData["vinView"] | null>(null);
  const claimAnalytics = useClaimAnalyticsAgentData();
  const signalEventAnalytics = useSignalEventsAnalyticsAgentData();
  const issues = getIssuesAgentData();

  useEffect(() => {
    const fetchData = async () => {
      const retrievedVinView = await getVinViewAgentData();

      setVinView(retrievedVinView);
    };

    fetchData();
  }, []);

  return {
    availableData: {
      claimAnalytics,
      signalEventAnalytics,
      vinView,
      issues,
    },
    getPageState,
  };
};

export const useDuckVisibility = (forceOpen?: boolean) => {
  const [open, setOpen] = useState(forceOpen || getInitialVisibility());

  if (forceOpen) {
    persistVisibility(true);
  }

  const setIsDuckVisible = (visible: boolean) => {
    setOpen(visible);
    persistVisibility(visible);
  };

  return { isDuckVisible: open, setIsDuckVisible };
};

/**
 * @summary This hook provides a thread id for the Duck agent, and also provides
 * a mechanism to reset it. Resetting the memory of the Duck session is accomplished
 * by resetting the thread id.
 * @returns The current thread id and a function to reset it.
 */
export const useThreadId = () => {
  const [threadId, setThreadId] = useState(getInitialThreadId());

  const resetThreadId = () => {
    const updatedThreadId = randomID();
    if (sessionStorage) {
      sessionStorage.setItem(LANGCHAIN_THREAD_ID_KEY, updatedThreadId);
    }

    setThreadId(updatedThreadId);
  };

  return { threadId, resetThreadId };
};

/**
 * When using this hook, we must be sure that the hook does not get
 * recreated based on its location in the component tree. If it does, the agent
 * would be working with a disconnected instance of the hook that does not
 * actually do anything.
 * A simple solution is to use the hook on a component high in the hierarchy
 * that is not likely to be re-rendered often.
 */
export const useQueryStringNavigation = (): QueryStringNavigation => {
  const navigate = useNavigate();
  const initialReloadRequired =
    sessionStorage.getItem(DUCK_RELOAD_REQUIRED_KEY) ?? Reload.NONE;
  const reloadRequiredRef = useRef<Reload>(initialReloadRequired as Reload);

  const initialUpdatedQueryParams = getInitialUpdatedQueryParams();
  const [updatedQueryParams, setUpdatedQueryParamsInternal] = useState<
    Record<string, string>
  >(initialUpdatedQueryParams);

  const setUpdatedQueryParams: Dispatch<
    SetStateAction<Record<string, string>>
  > = (action) => {
    setUpdatedQueryParamsInternal((prev) => {
      if (typeof action === "function") {
        return action(prev);
      } else {
        return action;
      }
    });
  };

  useEffect(() => {
    sessionStorage.setItem(
      DUCK_UPDATED_QUERY_PARAMS_KEY,
      JSON.stringify(updatedQueryParams)
    );
  }, [updatedQueryParams]);

  // When setting the sort parameter, we need to remove an existing conflicting
  // sort parameter, if it exists. The conflicting sort param will share the same
  // root name but will have a different ending.
  // An example is: `sort_v1.vehicles[mileage]=desc`
  // If we wanted to set a param like `sort_v1.vehicles_table[vehicleModelYear]=asc`,
  // we would need to remove the existing sort param first. The name of the
  // conflicting sort param would start with `sort_v1.vehicles_table[`.
  const initialOmitExistingQueryParamsStartingWith =
    sessionStorage.getItem(DUCK_OMIT_EXISTING_QUERY_PARAMS_STARTING_WITH_KEY) ??
    undefined;
  const [
    omitExistingQueryParamsStartingWith,
    setOmitExistingQueryParamsStartingWithInternal,
  ] = useState<string | undefined>(initialOmitExistingQueryParamsStartingWith);

  const setOmitExistingQueryParamsStartingWith = (
    prefix: string | undefined
  ) => {
    setOmitExistingQueryParamsStartingWithInternal(prefix);
    if (prefix === undefined) {
      sessionStorage.removeItem(
        DUCK_OMIT_EXISTING_QUERY_PARAMS_STARTING_WITH_KEY
      );
    } else {
      sessionStorage.setItem(
        DUCK_OMIT_EXISTING_QUERY_PARAMS_STARTING_WITH_KEY,
        prefix
      );
    }
  };

  // This is also used for sorting. To extend the prior example, it is possible
  // that there would be a degenerate query param that indicates that there is no
  // sort applied, i.e. `sort_v1.vehicles_table=`
  // We need to be sure to get rid of that also so it doesn't conflict with a new
  // sort we are setting.
  const initialOmitExistingQueryParam =
    sessionStorage.getItem(DUCK_OMIT_EXISTING_QUERY_PARAM_KEY) ?? undefined;
  const [omitExistingQueryParam, setOmitExistingQueryParamInternal] = useState<
    string | undefined
  >(initialOmitExistingQueryParam);

  const setOmitExistingQueryParam = (paramName: string | undefined) => {
    setOmitExistingQueryParamInternal(paramName);
    if (paramName === undefined) {
      sessionStorage.removeItem(DUCK_OMIT_EXISTING_QUERY_PARAM_KEY);
    } else {
      sessionStorage.setItem(DUCK_OMIT_EXISTING_QUERY_PARAM_KEY, paramName);
    }
  };

  // The routeValue corresponds to the page in the app
  const initialRouteValue = sessionStorage.getItem(DUCK_ROUTE_VALUE_KEY) ?? "";
  const [routeValue, setRouteValueInternal] = useState(initialRouteValue);

  const setRouteValue = (routeValue: string): void => {
    setRouteValueInternal(routeValue);
    sessionStorage.setItem(DUCK_ROUTE_VALUE_KEY, routeValue);
  };

  const setReloadRequired = (reload: Reload): void => {
    reloadRequiredRef.current = reload;
    sessionStorage.setItem(DUCK_RELOAD_REQUIRED_KEY, reload);
  };

  const getPriorQueryParams = (): ParsedQs => {
    if (window.location.pathname !== routeValue) {
      // We are going to a new page. Discard the query params from the old page.
      return {};
    }

    const priorQueryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (!omitExistingQueryParamsStartingWith && !omitExistingQueryParam) {
      return priorQueryParams;
    }

    return Object.fromEntries(
      Object.entries(priorQueryParams).filter(
        ([key]) =>
          !omitExistingQueryParamsStartingWith ||
          (!key.startsWith(omitExistingQueryParamsStartingWith) &&
            (!omitExistingQueryParam || key !== omitExistingQueryParam))
      )
    );
  };

  const deliverLocationInfo = (reset: boolean = true): LocationInfo => {
    const newQueryParams = {
      ...getPriorQueryParams(),
      ...updatedQueryParams,
    };

    const path = createPath({
      pathname: routeValue,
      search: qs.stringify(newQueryParams, { arrayFormat: "indices" }),
      hash: window.location.hash,
    });

    const response = {
      reloadRequired: reloadRequiredRef.current,
      path,
      url: `${window.location.origin}${path}`,
    };

    if (reset) {
      clearLocationInfo();
    }

    return response;
  };

  /**
   * Clear the data managed by this utility.
   */
  const clearLocationInfo = () => {
    setUpdatedQueryParams(() => ({}));
    setReloadRequired(Reload.NONE);
  };

  const updateLocation = (): void => {
    const { path, url, reloadRequired } = deliverLocationInfo();
    console.log(`updateLocation: ${JSON.stringify({ reloadRequired, path })}`);
    if (reloadRequired === Reload.HARD) {
      window.location.assign(url);
    } else if (reloadRequired === Reload.SOFT) {
      navigate(path);
    }
  };

  const setMinimumReload = (reload: Reload) => {
    if (reload === Reload.HARD) {
      setReloadRequired(Reload.HARD);
    } else if (
      reload === Reload.SOFT &&
      reloadRequiredRef.current === Reload.NONE
    ) {
      setReloadRequired(Reload.SOFT);
    }
  };

  const updateQueryStringParameter = (
    paramName: string,
    paramValue: string,
    reload: Reload = Reload.NONE
  ): void => {
    setUpdatedQueryParams((prev) => ({ ...prev, [paramName]: paramValue }));

    setMinimumReload(reload);

    console.log(
      `${new Date().getTime()} set query string parameter "${paramName}" to "${paramValue}"`
    );
  };

  const navigateToTab = (tabId: string) => {
    updateQueryStringParameter(TAB_QUERY_PARAM, tabId, Reload.SOFT);
  };

  const updateFilter = (
    filterQueryString: string,
    queryStringParameterName: string
  ): void => {
    updateQueryStringParameter(
      queryStringParameterName,
      filterQueryString,
      Reload.HARD
    );
  };

  return {
    deliverLocationInfo,
    updateLocation,
    clearLocationInfo,
    setRouteValue,
    setMinimumReload,
    updateQueryStringParameter,
    navigateToTab,
    updateFilter,
    setOmitExistingQueryParam,
    setOmitExistingQueryParamsStartingWith,
  };
};

const overrideAccessToKnightSwiftDuckDemo = (
  ksDemoQsParam: ParsedQs[string]
): boolean | undefined => {
  const KNIGHTSWIFT_DUCK_DEMO_KEY = "knightSwiftDuckDemo";

  if (ksDemoQsParam === "false" || ksDemoQsParam === "true") {
    localStorage.setItem(KNIGHTSWIFT_DUCK_DEMO_KEY, ksDemoQsParam);

    return ksDemoQsParam === "true";
  }

  if (ksDemoQsParam === "") {
    localStorage.removeItem(KNIGHTSWIFT_DUCK_DEMO_KEY);

    return undefined;
  }

  const ksDemoLocalStorage = localStorage.getItem(KNIGHTSWIFT_DUCK_DEMO_KEY);

  if (ksDemoLocalStorage === "false") return false;

  if (ksDemoLocalStorage === "true") return true;

  if (ksDemoLocalStorage !== null) {
    localStorage.removeItem(KNIGHTSWIFT_DUCK_DEMO_KEY);
  }

  return undefined;
};

export const useDuckAccess = (): DuckAccess => {
  const flags = useFlags();
  const { ksDemo } = useQuery();
  const { ECUs } = useContext(VehicleECUsAttributeContext);
  const { options } = useContext(VehicleOptionsAttributeContext);

  return getDuckAccess(
    flags,
    Boolean(ECUs?.length),
    Boolean(options?.length),
    overrideAccessToKnightSwiftDuckDemo(ksDemo)
  );
};

export const usePendingAction = (): PendingAction => {
  const initialPendingAction =
    sessionStorage.getItem(DUCK_PENDING_ACTION_KEY) === "true";

  const [pendingAction, setPendingActionInternal] =
    useState(initialPendingAction);

  const setPendingAction = (pendingAction: boolean) => {
    setPendingActionInternal(pendingAction);
    if (pendingAction) {
      sessionStorage.setItem(DUCK_PENDING_ACTION_KEY, "true");
    } else {
      sessionStorage.removeItem(DUCK_PENDING_ACTION_KEY);
    }
  };

  return { pendingAction, setPendingAction };
};

export const useCtrlDKeyPress = () => {
  const [isCtrlDKeyPressed, setIsCtrlDKeyPressed] = useState(false);
  const dKey = "d";

  useEffect(() => {
    const downHandler = (event: KeyboardEvent) => {
      if (event.key === dKey && event.ctrlKey) {
        setIsCtrlDKeyPressed(true);
      }
    };

    const upHandler = (event: KeyboardEvent) => {
      if (event.key === dKey && event.ctrlKey) {
        setIsCtrlDKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);

  return isCtrlDKeyPressed;
};

export enum SchemaUsage {
  FILTER = "filter",
  SORT = "sort",
}

export const useSchema = (
  source: string,
  usage: SchemaUsage
): SchemaEntry[] => {
  const { schema: claimsSchema } = useClaimsSchema();
  const { schema: vehiclesSchema } = useVehiclesSchema();
  const { schema: signalEventOccurencesSchema } =
    useSignalEventOccurrencesSchema();
  const { schema: issuesSchema } = useIssuesSchema();
  const { schema: suggestedIssuesSchema } = useSuggestedIssuesSchema();

  // The keys in this map should be all lower case.
  // This simplifies getting a match from the parameter source.
  const unfilteredSchemaMap: Record<string, SchemaEntry<string>[]> = {
    claims: claimsSchema,
    vehicles: vehiclesSchema,
    signaleventoccurrence: signalEventOccurencesSchema,
    issues: issuesSchema,
    suggestedissues: suggestedIssuesSchema,
  };

  return (unfilteredSchemaMap[source.toLowerCase()] ?? []).filter(
    (schemaEntry) => {
      if (usage === SchemaUsage.SORT) return schemaEntry.sortable;

      if (usage === SchemaUsage.FILTER)
        return schemaEntry.filter && !schemaEntry.hideFilter;

      return false;
    }
  );
};
