import {
  ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
  ATTRIBUTE_RETRIEVAL_K,
  ATTRIBUTE_SOURCE_KEYS,
} from "duck/graph/constants";
import {
  FilterSource,
  getValidateFilter,
} from "duck/graph/nodes/ClaimAnalyticsAgent/utils";
import { DEFAULT_CODE_SEARCH_RESULT_LIMIT } from "duck/graph/nodes/CodeSearchByDescriptionAgent/getNode";
import { PageHandler } from "duck/graph/PageHandler";
import getAttributeRetrievalTool from "duck/graph/tools/getAttributeRetrievalTool";
import getCodeSearchByDescriptionsTool from "duck/graph/tools/getCodeSearchByDescriptionsTool";
import getGoToAssociatedSignalEventsTabTool from "duck/graph/tools/getGoToAssociatedSignalEventsTabTool";
import getGoToByVehiclesAgeTabTool from "duck/graph/tools/getGoToByVehiclesAgeTabTool";
import getGoToTabTool from "duck/graph/tools/getGoToTabTool";
import getGoToTopContributorsTabTool from "duck/graph/tools/getGoToTopContributorsTabTool";
import getRetrieveTopContributorsGroupByOptionsTool from "duck/graph/tools/getRetrieveTopContributorsGroupByOptionsTool";
import getUpdateFilterTool from "duck/graph/tools/getUpdateFilterTool";
import { DuckGraphParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

import {
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import { CLAIM_ANALYTICS_ASSOCIATED_SIGNAL_EVENTS_PAGE_KEY } from "pages/ClaimAnalytics/tabPages/AssociatedSignalEvents";
import { BY_VEHICLE_AGE_CHART_OPTIONS_KEY } from "pages/ClaimAnalytics/tabPages/ByVehicleAge/ByVehicleAge";
import {
  CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
  CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
  DEFAULT_GROUP_BY_ATTRIBUTE,
} from "pages/ClaimAnalytics/tabPages/TopContributors/TopContributors";
import {
  ASSOCIATED_VEHICLES_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_TITLE,
} from "pages/constants";

import { RelatesFilterWindowDirection } from "features/ui/Filters/types";

import getGoToClaimsTabTool from "./getGoToClaimsTabTool";

/**
 * Get the retrieval tools for the claim analytics agent.
 * These tools are related to attribute retrieval.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * @returns The set of retrieval tools for the claim analytics agent.
 */
export const getRetrievalTools = (
  params: DuckGraphParams
): StructuredTool[] => [
  getAttributeRetrievalTool(
    ATTRIBUTE_SOURCE_KEYS.Claim,
    ATTRIBUTE_RETRIEVAL_K,
    ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
    params.uiHandlers.setEphemeralMessage
  ),
  getAttributeRetrievalTool(
    ATTRIBUTE_SOURCE_KEYS.Vehicle,
    ATTRIBUTE_RETRIEVAL_K,
    ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
    params.uiHandlers.setEphemeralMessage
  ),
  getRetrieveTopContributorsGroupByOptionsTool(
    params.availableData.claimAnalytics.topContributorsGroupByOptions,
    params.uiHandlers.setEphemeralMessage
  ),
  getCodeSearchByDescriptionsTool({
    source: "laborCodes",
    numResults: DEFAULT_CODE_SEARCH_RESULT_LIMIT,
  }),
  getCodeSearchByDescriptionsTool({
    source: "parts",
    numResults: DEFAULT_CODE_SEARCH_RESULT_LIMIT,
  }),
];

/**
 * Get the action tools for the claim analytics agent.
 * These tools are related to actions.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * @param pageHandler The page handler for managing UI interactions.
 * @returns The set of action tools for the claim analytics agent.
 */
export const getActionTools = (
  params: DuckGraphParams,
  pageHandler: PageHandler
): StructuredTool[] => [
  getUpdateFilterTool({
    pageHandler,
    pageKey: CLAIMS_PAGE_KEY,
    validateFunction: getValidateFilter(FilterSource.CLAIM),
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    setAgentResponse: params.uiHandlers.setAgentResponse,
    source: FilterSource.CLAIM,
    selectedFilterString:
      params.currentState.claimAnalytics.claimsFilterQueryString,
  }),
  getUpdateFilterTool({
    pageHandler,
    pageKey: VEHICLES_PAGE_KEY,
    validateFunction: getValidateFilter(FilterSource.VEHICLE),
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    setAgentResponse: params.uiHandlers.setAgentResponse,
    source: FilterSource.VEHICLE,
    selectedFilterString:
      params.currentState.claimAnalytics.vehiclesFilterQueryString,
  }),
  getGoToClaimsTabTool({
    pageHandler,
    claimsChartOptions: params.availableData.claimAnalytics.claimsChartOptions,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    setAgentResponse: params.uiHandlers.setAgentResponse,
    selectedTab: params.currentState.selectedTab,
    selectedClaimsChartOptions:
      params.currentState.claimAnalytics.selectedClaimsChartOptions,
  }),
  getGoToByVehiclesAgeTabTool({
    pageHandler,
    chartOptions: params.availableData.claimAnalytics.byVehicleAgeChartOptions,
    pageKey: CLAIMS_PAGE_KEY,
    chartOptionsKey: BY_VEHICLE_AGE_CHART_OPTIONS_KEY,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    setAgentResponse: params.uiHandlers.setAgentResponse,
    selectedTab: params.currentState.selectedTab,
    selectedByVehicleAgeChartOptions:
      params.currentState.claimAnalytics.selectedByVehicleAgeChartOptions,
  }),
  getGoToTopContributorsTabTool({
    pageHandler,
    chartOptions:
      params.availableData.claimAnalytics.topContributorsChartOptions,
    groupByOptions:
      params.availableData.claimAnalytics.topContributorsGroupByOptions,
    selectedTab: params.currentState.selectedTab,
    selectedChartOptions:
      params.currentState.claimAnalytics.selectedTopContributorsChartOptions,
    selectedGroupByAttribute:
      params.currentState.claimAnalytics.selectedGroupByAttribute,
    defaultGroupByAttribute: DEFAULT_GROUP_BY_ATTRIBUTE,
    pageKey: CLAIMS_PAGE_KEY,
    chartOptionsKey: CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    groupByOptionsKey: CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    setAgentResponse: params.uiHandlers.setAgentResponse,
  }),
  getGoToTabTool({
    pageHandler,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    setAgentResponse: params.uiHandlers.setAgentResponse,
    tabKey: ASSOCIATED_VEHICLES_TAB_KEY,
    tabTitle: ASSOCIATED_VEHICLES_TAB_TITLE,
    selectedTabKey: params.currentState.selectedTab,
  }),
  getGoToAssociatedSignalEventsTabTool({
    pageHandler,
    pageKey: CLAIM_ANALYTICS_ASSOCIATED_SIGNAL_EVENTS_PAGE_KEY,
    windowDirectionOptions: [RelatesFilterWindowDirection.BEFORE],
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    setAgentResponse: params.uiHandlers.setAgentResponse,
    selectedTab: params.currentState.selectedTab,
    selectedWindowSize:
      params.currentState.claimAnalytics.signalEventOccurrencesWindowSize,
    selectedWindowDirection:
      params.currentState.claimAnalytics.signalEventOccurrencesWindowDirection,
  }),
];
