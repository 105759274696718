import React from "react";

import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  RelatesFilterState,
  RelatesFilterWindowDirectionType,
  UseFilterSortState,
} from "features/ui/Filters/types";

import RelatesFilter from "./RelatesFilter";

interface RelatesFilterFormProps {
  relatesFilter: RelatesFilterState;
  setRelatesFilter: (row: RelatesFilterState) => void;
  appliedRelatesFilter: RelatesFilterState;
  setAppliedRelatesFilter: (row: RelatesFilterState) => void;
  filterSortState: UseFilterSortState;
  pendingFiltersKey: string;
  baseEntityText: string;
  windowDirectionOptions?: DropdownSelectOption<RelatesFilterWindowDirectionType>[];
}

const AssociatedSignalEventsRelatesFilter = ({
  relatesFilter,
  setRelatesFilter,
  appliedRelatesFilter,
  setAppliedRelatesFilter,
  filterSortState,
  pendingFiltersKey,
  baseEntityText,
  windowDirectionOptions,
}: RelatesFilterFormProps) => {
  const filterTitle = "Associated Signal Event defined by";
  const onApplyFilters = (newFilters: FilterGroupState) => {
    setRelatesFilter({
      ...relatesFilter,
      filters: newFilters,
    });
    setAppliedRelatesFilter({
      ...appliedRelatesFilter,
      filters: newFilters,
    });
  };

  const onUpdateRelatesFilter = (row: RelatesFilterState) => {
    setRelatesFilter(row);
  };

  return (
    <>
      <RelatesFilter
        filterTitle={filterTitle}
        baseEntityText={baseEntityText}
        relatesFilter={relatesFilter}
        filterSortState={filterSortState}
        pendingFiltersKey={pendingFiltersKey}
        selectorTestId="associated-signal-event-filters"
        inFilterSelector={false}
        onApplyFilters={onApplyFilters}
        onUpdate={onUpdateRelatesFilter}
        windowDirectionOptions={windowDirectionOptions}
      />
    </>
  );
};

export default AssociatedSignalEventsRelatesFilter;
